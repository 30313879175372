import React from 'react';
import { Container,Box } from 'theme-ui';
import SEO from '../components/seo';
import SecondaryLayout from '../components/layout/secondary/secondary';
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import get from 'lodash/get';
import styles from '../components/home-banner/home-banner.style';

const sellerPageStaticQuery = graphql`
	query {
		prismic {
			allChat2ordersupports {
				edges {
					node {
					  our_support,
					  seller_support,
					  chat2order_support,
					  support_banner
					}
				}
			}
		}
	}
`;

const aboutPage: React.FC<{}> = () => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${sellerPageStaticQuery}`}
		render={(data) => {
			const node = get(data, 'prismic.allChat2ordersupports.edges[0].node');
			const supportInfo = node.our_support;
			const seller_support = node.seller_support;
			const chat2order_support = node.chat2order_support;
			const support_banner = node.support_banner.url;

			const sellerRef = React.useRef();
			const chat2orderRef = React.useRef();
			function scrollToComponent() {
			
			switch (window.location.hash) {
			case '#sellerRef' :
				sellerRef.current.scrollIntoView();
				sellerRef.current.focus();
				break;
			case '#chat2orderRef' :
				chat2orderRef.current.scrollIntoView();
				chat2orderRef.current.focus();
				break;	
				}

			}

			React.useEffect( () => scrollToComponent(), [] )

			return (
				<SecondaryLayout>
					<SEO title="our support" />
					
					<Container py="40px">
					{RichText.render(supportInfo)}
					</Container>

					<Container><p ref={sellerRef} id='sellerRef'></p></Container>
					
				
					<Container py="40px">
						
						{RichText.render(seller_support)}
					</Container>

					<Container><p ref={chat2orderRef} id='chat2orderRef'></p></Container>
					
					<Container py="40px">
						
						{RichText.render(chat2order_support)}
					</Container>

					<Box
						as="section"
						sx={{
							backgroundImage: `url(${support_banner && support_banner ? support_banner : bannerImg})`,
							...styles.banner,
						}}
						className="home-banner"
					>		
					</Box>	

					

				</SecondaryLayout>

			);
		}}
	/>
);

export default aboutPage;
